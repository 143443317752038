import React from 'react';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type SectionTitleProps = {
  titleBg: string;
  title: string;
};

const useStyles = makeStyles({
  titleBox: {
    position: `relative`,
    height: `100px`,
    marginTop: `-24px`,
  },
  titleBg: {
    position: `absolute`,
    fontWeight: 900,
    fontSize: `3.6em`,
    color: `#E5E5E5`,
    left: `0`,
    top: `0`,
  },
  title: {
    position: `absolute`,
    fontWeight: 900,
    fontSize: `2.4em`,
    left: `0.8em`,
    top: `1.6em`,
    margin: `0 auto`,
    color: `#333138`,
  },
});

const SectionTitle: React.VFC<SectionTitleProps> = (props) => {
  const { titleBg, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.titleBox}>
      <Typography className={classes.titleBg}>{titleBg}</Typography>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

export default SectionTitle;
