import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SectionTitle from '@/components/common/basic/SectionTitle';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: `calc(1rem + 2vw) auto`,
    },
    container: {
      margin: `calc(1rem + 2vw) auto`,
      width: `100%`,
    },
    subTitle: {
      fontWeight: 600,
      fontStyle: `italic`,
      [theme.breakpoints.up(`sm`)]: {
        textAlign: `center`,
      },
      [theme.breakpoints.up(`md`)]: {
        fontSize: `1.2rem`,
        lineHeight: 3,
      },
    },
    rightContentBox: {
      [theme.breakpoints.up(`sm`)]: {
        width: `95%`,
        marginRight: 0,
        marginLeft: `auto`,
      },
    },
    leftContentBox: {
      [theme.breakpoints.up(`sm`)]: {
        width: `95%`,
        marginRight: `auto`,
        marginLeft: 0,
      },
    },
    strong: {
      color: `#FF312E`,
      fontSize: `1.2em`,
    },
    brAdjust: {
      display: `inline-block`,
    },
  }),
);

const SecondBlock: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <SectionTitle titleBg="International" title="世界を翔ける" />
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={8}>
          <Typography className={classes.subTitle}>
            <span className={classes.brAdjust}>三井物産時代は、</span>
            <span className={classes.brAdjust}>資源エネルギーを担当。</span>
            <br />
            <span className={classes.brAdjust}>アジア、中東を駆け巡る。</span>
            <span className={classes.brAdjust}>
              多様な文化に出会い日本を見つめる。
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StaticImage
            alt="1985三井GE研修_NUSAC訪問"
            src="../../../images/profile/SecondBlockImage01.jpg"
          />
          <Typography variant="caption">
            三井物産時代の研修でNUSAC訪問
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <StaticImage
            alt="1983東電福島第一原発勤務風景"
            src="../../../images/profile/SecondBlockImage02.jpg"
          />
          <Typography variant="caption">
            東京電力福島第一原子力発電所へ出向
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.rightContentBox}>
            <Typography variant="h2">
              <span className={classes.strong}>職</span>歴 / 海外勤務：10年
            </Typography>
            <Typography>
              ・三井物産株式会社
              <br />
              ・KDDI株式会社
              <br />
              ・タタコミュニケーションズジャパン株式会社
              <br />
              ・翔鷺騰龍集団公司
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={7}>
          <div className={classes.leftContentBox}>
            <Typography>
              三井物産時代に台湾・中国など主に東南アジアを中心に活躍。
              <br />
              資源エネルギーや機械を担当。営の神様ジャックウェルチ米国GEからの発電設備輸入、一世を風靡した携帯端末ブラックベリーは、中高年インテリに訴求力。ノウハウを生かし現在のインターネットの礎に貢献し、ITのコンサルも務める。
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <StaticImage
            alt="1987台湾野球"
            src="../../../images/profile/SecondBlockImage03.jpg"
          />
          <Typography variant="caption">台湾生活時も野球を楽しむ</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={5}>
          <StaticImage
            alt="1987インドタージマハール"
            src="../../../images/profile/SecondBlockImage04.jpg"
          />
          <Typography variant="caption">インドのタージマハールにて</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={classes.rightContentBox}>
            <Typography>
              インド大手企業集団『タタ』の通信子会社の日本法人のトップを勤めた。インド人とのコミュニケーションをも学ぶ。
              <br />
              厦門の台湾企業Tuntexで原油買付の責任者として中東との取引も担当。
              <br />
              海外勤務の10年間で世界から見た日本を学ぶ。
            </Typography>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default SecondBlock;
