import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import SectionTitle from '@/components/common/basic/SectionTitle';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: `calc(1rem + 2vw) auto`,
    },
    container: {
      margin: `calc(1rem + 2vw) auto`,
      width: `100%`,
    },
    subTitle: {
      fontWeight: 600,
      fontStyle: `italic`,
      [theme.breakpoints.up(`sm`)]: {
        textAlign: `center`,
      },
      [theme.breakpoints.up(`md`)]: {
        fontSize: `1.2rem`,
        lineHeight: 3,
      },
    },
    rightContentBox: {
      [theme.breakpoints.up(`sm`)]: {
        width: `95%`,
        marginRight: 0,
        marginLeft: `auto`,
      },
    },
    strong: {
      color: `#FF312E`,
      fontSize: `1.2em`,
    },
    brAdjust: {
      display: `inline-block`,
    },
    imageAdjust: {
      height: `240px`,
    },
  }),
);

const ThirdBlock: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <SectionTitle titleBg="Political" title="政治の世界へ" />
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <StaticImage
            alt="新宿区議として議会に登壇"
            src="../../../images/profile/ThirdBlockImage01.jpg"
          />
          <Typography variant="caption">新宿区議として議会に登壇</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.subTitle}>
            <span className={classes.brAdjust}>
              日本に戻り地域貢献をしたいと
            </span>
            <span className={classes.brAdjust}>新宿区議に挑戦し、当選。</span>
            <br />
            <span className={classes.brAdjust}>
              都市防災や機構改革に取り組む。
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={7}>
          <Typography variant="h2">
            <span className={classes.strong}>新</span>宿区議会での実績
          </Typography>
          <Typography>
            ・議会運営委員会副委員長
            <br />
            ・環境建設委員会理事
            <br />
            ・総務区民委員会理事
            <br />
            ・防災等特別対策委員会理事 ほか
            <br />
            ・東京維新の会政調会長代行
            <br />
            ・参議院議員秘書
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <StaticImage
            alt="区議会で地域防災の整備を訴える"
            src="../../../images/profile/ThirdBlockImage02.jpg"
          />
          <Typography variant="caption">
            区議会で地域防災の整備を訴える
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <StaticImage
            alt="区議から参議院議員公設秘書へ"
            src="../../../images/profile/ThirdBlockImage03.jpeg"
          />
          <Typography variant="caption">
            区議から参議院議員公設秘書へ
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.rightContentBox}>
            <Typography>
              商社マンとしての経験を生かして、地域の問題と向かいあってみると、根本的なところでは機構改革が不可欠だと認識を深める。
              <br />
              特に経済再生、成長戦略に関心を抱く。その為参議院秘書として国政の現場を経験する中で、日本の改革にお役に立ちたいと決意。
              <br />
              練馬に事務所を設け、地域の皆さんのご意見をお聞きしながら、期する日に向けて全力投球で活動中。
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <StaticImage
                alt="統一地方選挙選挙の応援へ_1"
                src="../../../images/profile/ThirdBlockImage04.jpg"
                className={classes.imageAdjust}
                objectPosition="50% 50%"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StaticImage
                alt="統一地方選挙選挙の応援へ_2"
                src="../../../images/profile/ThirdBlockImage05.jpg"
                className={classes.imageAdjust}
                objectPosition="50% 50%"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StaticImage
                alt="統一地方選挙選挙の応援へ_3"
                src="../../../images/profile/ThirdBlockImage06.jpg"
                className={classes.imageAdjust}
                objectPosition="50% 50%"
              />
            </Grid>
          </Grid>
          <Typography variant="caption">統一地方選挙選挙の応援へ</Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default ThirdBlock;
