import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import SectionTitle from '@/components/common/basic/SectionTitle';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: `calc(1rem + 2vw) auto`,
    },
    container: {
      margin: `calc(1rem + 2vw) auto`,
      width: `100%`,
    },
    subTitle: {
      fontWeight: 600,
      fontStyle: `italic`,
      [theme.breakpoints.up(`sm`)]: {
        textAlign: `center`,
      },
      [theme.breakpoints.up(`md`)]: {
        fontSize: `1.2rem`,
        lineHeight: 3,
      },
    },
    rightContentBox: {
      [theme.breakpoints.up(`sm`)]: {
        width: `95%`,
        marginRight: 0,
        marginLeft: `auto`,
      },
    },
    strong: {
      color: `#FF312E`,
      fontSize: `1.2em`,
    },
    brAdjust: {
      display: `inline-block`,
    },
  }),
);

const FirstBlock: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <SectionTitle titleBg="Impact & ..." title="衝撃と決意" />
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={5}>
          <StaticImage
            alt="1966神戸市立本山第一小学校野球部"
            src="../../../images/profile/FirstBlockImage01.jpg"
          />
          <Typography variant="caption">少年時代から野球一筋</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography className={classes.subTitle}>
            <span className={classes.brAdjust}>横浜国立大学時代に</span>
            <span className={classes.brAdjust}>アメリカに短期留学。</span>
            <br />
            <span className={classes.brAdjust}>カルチャーショックから、</span>
            <span className={classes.brAdjust}>
              グローバルな生き方を求める。
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={7}>
          <Typography variant="h2">
            <span className={classes.strong}>学</span>歴
          </Typography>
          <Typography>
            ・藤沢市立鵠沼小学校
            <br />
            ・藤沢市立鵠沼中学校
            <br />
            ・湘南高等学校
            <br />
            ・横浜国立大学工学部電子情報科
            <br />
            ・横浜国立大学大学院
            <br />
            ・臺灣師範大学 国語中心
            <br />
            ・早稲田大学公共経営 大学院
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <StaticImage
            alt="1986台湾師範大学孔先生を囲んで"
            src="../../../images/profile/FirstBlockImage02.jpg"
          />
          <Typography variant="caption">台湾で中国語を学ぶ</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <StaticImage
            alt="2018.2片山ゼミ"
            src="../../../images/profile/FirstBlockImage03.jpg"
          />
          <Typography variant="caption">早稲田大学で行政を学ぶ</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.rightContentBox}>
            <Typography>
              湘南高校時代に甲子園を目指すが高校3年の時の春季県大会で原辰徳（現巨人監督）の東海大相模と対決しましたが、力及ばず、甲子園を諦めました。
              <br />
              横浜国立大学では情報システムや集積エレクトロニクスなど最先端の電子技術の研究をしていました。
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography>
            大学4年時には、米国フィラデルフィアでの学会の発表に助教授の代理で出席、同時に1ヶ月の米国放浪の旅をしました。
            <br />
            この放浪の経験が、国境にとらわれない生き方を選ぶ転機だったと言えます。
            <br />
            社会人になってからも多くの機会をいただき、台湾の臺灣師範大学では中国語標準語、早稲田大学公共経営大学院では行政の仕組みと政治との関係と、幅広い分野を学んできました。
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default FirstBlock;
