import React from 'react';
import { PageProps } from 'gatsby';

import Container from '@material-ui/core/Container';
import Layout from '@/components/others/Layout';
import FirstView from '@/components/Profile/section/FirstView';
import StaticSEO from '@/components/common/section/StaticSEO';
import FirstBlock from '@/components/Profile/section/FirstBlock';
import SecondBlock from '@/components/Profile/section/SecondBox';
import ThirdBlock from '@/components/Profile/section/ThirdBlock';

const Profile: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      OgImageData="FirstViewSM"
      titleData="藤川たかし 公式HP | プロフィール"
    />
    <FirstView />
    <Container maxWidth="md">
      <FirstBlock />
      <SecondBlock />
      <ThirdBlock />
    </Container>
  </Layout>
);

export default Profile;
